<template>
<form @submit.prevent="">
    <div class="form-row">
        <div class="col-12">
            <div class="alert-line p-0">
                از طریق لینک زیر به روبات مورد نظر ورود کرده و دستور زیر را در آن وارد کنید
            </div>
            <div v-if="show_msg" class="alert alert-success text-center" role="alert">
                با موفقیت در حافظه ذخیره شد
            </div>
        </div>
        <div @click="copyClipboard()" id="token" :value="'/token' + data.token" class="link-row-box cursor-pointer">
            <div class="link-row-box-icon">
                <i class="fa fa-copy"></i>
            </div>
            <div class="link-row-box-content">
                {{ '/token '+ data.token }}
            </div>
        </div>
        <div class="link-row-box">
            <div class="link-row-box-icon">
                <i class="fa fa-link"></i>
            </div>
            <div class="link-row-box-content">
                <a target="_blank" :href="data.link">
                    <span style="display: inline-block; direction: ltr;">
                        {{ data.link }}
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 ">
            <button type="button" class="col-xs-1 center-block w-100 btn btn-primary rename-dashboard w-100" @click="saveChanges()">
                ذخیره تغییرات
            </button>
        </div>
    </div>
</form>
</template>

<style lang="scss">
.link-row-box {
    display: flex;
    text-align: left;
    direction: ltr;
    width: 100%;
    background: #e9ebf0;
    padding: 10px 20px;
    margin: 15px 15px 0;
}

.link-row-box .link-row-box-icon {
    display: flex;
    flex: 30px 0 0;
    align-items: center;
}

.link-row-box .link-row-box-content {
    font-size: 14px;
    letter-spacing: .5px;
}
</style>

<script>
// این کامپوننت مودال دریافت توکن مربوط به اصتال مسنجر  در پروفایل کاربر می باشد
export default {
    name: 'MessengerToken',
    props: ['data'],
    components: {},
    data: function () {
        return {
            show_msg: false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {}, { immediate: true });
    },
    methods: {
        // این متد وظیفه کپی کردن توکن به حافظه کاربر می باشد
        copyClipboard() {
            let self = this;
            var text = '/token ' + this.data.token;
            navigator.clipboard.writeText(text).then(function () {
                self.show_msg = true;
                setTimeout(function () {
                    self.show_msg = false;
                }, 3000);
            }, function (err) {
                // console.error('Could not copy text: ', err);
            });
        },
        // این متد باعث بسته شدن مودال می شود
        saveChanges() {
            this.$parent.closeModal();
        },
    },
}
</script>
